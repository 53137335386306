<template>
  <div class="menuWrapper">
    <div class="menu_inner">
  <ul class="linksContainer">
    <li>
      <router-link  @click="aboutUsHandler"
                    @blur="handlerClick"
                    class="navLi relativeElement"

                    style="text-decoration: none" to="">
        Про нас
      </router-link>

    </li>
    <ul class="dropDownMenu"
        @mouseover="this.mouseoverHandler"
        @mouseleave="this.mouseLeaveHandler"
        v-if="dropDownToggle">
      <li class="navLi"
          @click="dMenu_handler">
        <router-link style="text-decoration: none" to="/about">Про нас</router-link>
      </li>
      <li class="navLi" @click="dMenu_handler">
        <router-link style="text-decoration: none" to="/law">Правова інформація</router-link>
      </li>
      <li class="navLi" @click="dMenu_handler">
        <router-link style="text-decoration: none" to="/contacts">Контакти</router-link>
      </li>
    </ul>
    <li>
      <router-link class="navLi" style="text-decoration: none" to="/cash">Кошти в кредит</router-link>
    </li>
    <li>
      <router-link class="navLi" style="text-decoration: none" to="/good">Кредит на товар</router-link>
    </li>
    <li>
      <router-link class="navLi" style="text-decoration: none" to="/creditCalculator">Кредитні продукти</router-link>
    </li>
    <li>
      <router-link class="navLi" style="text-decoration: none" to="/FAQ">Поширені
        питання</router-link>
    </li>
    <li>
      <router-link class="navLi" style="text-decoration: none" to="/news">Новини</router-link>
    </li>
    <li  @click="linkRedirector(linkToCab)">
      <router-link class="navLi" style="text-decoration: none" to="">Особистий кабінет</router-link>
    </li>
    <li @click="linkRedirector(linkToPay)">
      <router-link class="navLi" style="text-decoration: none" to="">Оплата PSP Platon (Visa, MasterCard)</router-link>
    </li>
    <li @click="linkRedirector(linkToPayCity24)">
      <router-link class="navLi" style="text-decoration: none" to="">Сплатити City24</router-link>
    </li>
    <div class="phoneContainer">
      <a class="header_phone" href="tel:38 044 593 66 33">38 044 593 66 33</a>
      <span class="clientCareService">Служба турботи про клієнтів</span>
      <div class="media_container">
        <div style="max-width: 40px;margin: 5px 5px"
             @click="onClickHandler('https://t.me/sfactor_bot')"
        >
          <img
              src="@/assets/media-logos/telegram.png"
              alt="telegram"
              style="cursor: pointer; max-width: 1.5rem"
          />
        </div>
        <div style="max-width: 40px;margin: 5px 5px"
             @click="onClickHandler('viber://pa?chatURI=sfactor_bot')"
        >
          <img
              src="@/assets/media-logos/viber.png"
              alt="viber"
              style="cursor: pointer; max-width: 1.5rem"
          />
        </div>
      </div>
    </div>
  </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'mobMenu',
  components: {},
  methods: {
    linkRedirector(link){
      window.location.href = link
    },
    aboutUsHandler() {
      this.dropDownToggle = !this.dropDownToggle;
    },
    dMenu_handler() {
      this.dropDownToggle = false;
    },
    handlerClick(){
      if(this.mouseOver === true) return
      if(this.dropDownToggle === false) return
      if(this.dropDownToggle === true){
        this.dropDownToggle = false;
      }
    },
    mouseoverHandler() {
      this.mouseOver = true;
    },
    mouseLeaveHandler(){
      this.mouseOver = false;
    },
    onClickHandler(link) {
      window.open(link, '_blank');
    },
  },
  data() {
    return {
      linkToCab: "https://my.sfactor.com.ua/",
      // linkToPay: "https://easypay.ua/catalog/bank/pogashenie-kredita/modern-factoring-sub/modern-factoring-credit?",
      linkToPay: "https://devplaton.com.ua/invoices/sfactor_client/",
      linkToPayCity24: "https://city24.ua/ua/pogashenie-kredita/credit-unions/suchasniy-faktoring",
      dropDownToggle: false,
      mouseOver: false,
    }
  }
}
</script>
<style lang="scss" scoped>
.menuWrapper{
  min-height: 100vh;
  min-width: 100vw;
  background-color: #efefe8;
  display: flex;
  justify-content: center;
  .menu_inner{
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;

  }
}
.linksContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 500px;
  justify-content: space-around;
  li {
    align-self: center;
    margin-right: 30px;
    .navLi {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Montserrat', Arial, sans-serif;
      margin: 0 auto;
    }
  }
}
.relativeElement{
  position: relative;
}

.phoneContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100px;
  justify-content: flex-start;
  .clientCareService{
    font-family: 'Montserrat',Arial,sans-serif;
    font-weight: 300;
    color: #000;
    font-size: 14px;
    line-height: 1.55;
  }
  .header_phone{
    font-size: 22px;
    color: rgb(18, 180, 35);
    font-weight: 700;
    line-height: 1.55;
    font-family: 'Montserrat', Arial, sans-serif;
  }
  .media_container{
    display: flex;
  }
}
li{
  text-decoration: none;
  list-style: none;
}
.dropDownMenu{
  background-color: #fdfff8;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  top: 155px;
  right:28%;
  align-items: center;
  z-index: 5;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 20%);
  .navLi{
    margin: 5px 10px 5px 10px;
  }
}
.dropDownMenu:before{
  position:absolute;
  content:"";
  left:40px;
  top:-10px;
  transform: scalex(0.8) rotate(135deg);
  width:30px;
  height:30px;
  background-color: #fdfff8;
}
.navLi{
  color: #3b3b3b;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat';
  list-style: none;
  margin-right: 20px;
  margin-left: 20px;
}
.routerLiWrapp {

}
.routerLiWrapp{
  width: 100%;
  display: flex;
}
</style>
